import {Inject, Injectable} from '@angular/core';
import {USER_AUTH_TOKEN} from "../../core/ports/user-auth.token";
import {UserAuthInterface} from "../../core/ports/user-auth.interface";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UserAuthUseCase {
    constructor(
        @Inject(USER_AUTH_TOKEN) private userAuthInterface: UserAuthInterface,
        private router: Router
    ) {}

    login(): void {
        return this.userAuthInterface.login();
    }

    logout(): void {
        this.userAuthInterface.logout();
        this.router.navigate(['/']);

    }

}
