import {ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideOAuthClient} from "angular-oauth2-oidc";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {authInterceptor} from "./infrastructure/interceptors/auth.interceptor";
import {USER_INFO_TOKEN} from "./core/ports/user-info.token";
import {UserInfoService} from "./infrastructure/services/user-info.service";
import {USER_AUTH_TOKEN} from "./core/ports/user-auth.token";
import {UserAuthService} from "./infrastructure/services/user-auth.service";
import {provideStore} from "@ngrx/store";
import {provideEffects} from "@ngrx/effects";
import {UserEffects} from "./application/state/effects/user.effects";
import {userReducer} from "./application/state/reducers/user.reducer";
import {PROJECT_TOKEN} from "./core/ports/project.token";
import {ProjectService} from "./infrastructure/services/project.service";
import {NOTIFICATION_TOKEN} from "./core/ports/notification.interface";
import {NotificationService} from "./infrastructure/services/notification.service";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {environment} from "../environments/environment";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {getMessaging, provideMessaging} from "@angular/fire/messaging";
import {notificationReducer} from "./application/state/reducers/notification.reducer";
import {NotificationEffects} from "./application/state/effects/notification.effects";

export const appConfig: ApplicationConfig = {
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideMessaging(() => getMessaging()),
        provideAuth(() => getAuth()),
        provideStore({ user: userReducer, notification: notificationReducer }), // Cung cấp Store với reducer
        provideEffects([UserEffects, NotificationEffects]),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideClientHydration(),
        provideAnimationsAsync(),
        provideAnimationsAsync(),
        provideOAuthClient(),
        provideHttpClient(
            withInterceptors([authInterceptor])
        ),
        {
            provide: USER_INFO_TOKEN,
            useClass: UserInfoService // Provide UserInfoService for the token
        },
        {
            provide: USER_AUTH_TOKEN,
            useClass: UserAuthService // Provide UserAuthService for the token
        },
        {
            provide: PROJECT_TOKEN,
            useClass: ProjectService // Provide ProjectService for the token
        },
        {
            provide: NOTIFICATION_TOKEN,
            useClass: NotificationService // Provide NotificationService for the token
        }
        ]
};
